import React, { useRef, useEffect } from 'react';
import './chatbox.css';

const chatUrl = "https://main.d13ajrtsyo6slc.amplifyapp.com/";
// import { ChatBox } from './Common/ChatBox';
//     <ChatBox/>
export function ChatBox() {

    // "https://main.d13ajrtsyo6slc.amplifyapp.com/"
    const isMobile = useRef(false);
    const chatRef = useRef(null);

    const updIframe = () => {
        if (isMobile.current) chatRef.current.classList.add('mobile');
        else chatRef.current.classList.remove('mobile');
        // console.log('updIframe');
        chatRef.current.contentWindow.postMessage({ 
            deviceType: isMobile.current ? 'mobile' : 'desktop', 
            renovationType: 'Basements'
        }, chatUrl );
    };

    useEffect(() => {
        const onWindowResize = (e) => {
            // console.log('resize', window.innerWidth );
            if (window.innerWidth >= 900) {
                if (isMobile.current) {
                    isMobile.current = false;
                    //  console.log('setMobileView false', isMobile.current);
                     updIframe();
                }
            } else {
                if (!isMobile.current) {
                    isMobile.current = true;
                    //  console.log('setMobileView true', isMobile.current);
                     updIframe();
                }
            }
        };
        
        if (chatRef.current) {
            
            chatRef.current.addEventListener('load', function() {
                // This function will be executed when the iframe content has loaded
                // console.log('IFrame content has been loaded!');

                setTimeout(()=> {
                    window.addEventListener('resize', onWindowResize);

                    chatRef.current.style.visibility = 'visible';
                    updIframe();
                    onWindowResize();
                }, 500);

            });

        }

        window.addEventListener('message', function(event) {
            // console.log('message', event, );

            if (event.origin === chatUrl || true) {
              if (event.data) {

                if (event.data.isOpen === true) {
                    chatRef.current.classList.add('open');

                } else if (event.data.isOpen === false) {
                    chatRef.current.classList.remove('open');
                }

                if (event.data.isDrag === 'start') {
                    // chatRef.current.classList.add('open');
                    // if (!chatRef.current.dataset.height) chatRef.current.dataset.height = 485;
                    // chatRef.current.style.height = `800px`;
                    // chatRef.current.style.bottom = '-331px';
                    
                } else if (event.data.isDrag === 'end') {
                    // chatRef.current.classList.remove('open');
                    // chatRef.current.dataset.height = Number(event.data.height) + 30;
                    // chatRef.current.style.height = Number(event.data.height) + 30 + 'px';
                }


              }
            }
        });

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    return (
        <iframe style={{ visibility: 'hidden' }} ref={chatRef} id="chatBox" src={chatUrl} frameBorder="0"/>
    );
}